<!--
 * @Description: 修改订单-添加收款
 * @Author: 琢磨先生
 * @Date: 2022-10-09 10:55:16
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-09 10:55:26
-->
<template>
  <div>
    <el-divider direction="horizontal" content-position="left">
      <b>收款信息</b>
    </el-divider>
    <el-table :data="receiptList" border :show-header="false">
      <el-table-column label width="140">
        <template #default="scope">
          <el-select v-model="scope.row.type" placeholder>
            <el-option
              :label="item.text"
              :value="item.value"
              v-for="item in enums.receipt_types"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label width="160">
        <template #default="scope">
          <el-select
            v-model="scope.row.payment_term_id"
            placeholder
            @change="change"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in pay_term_list"
              :key="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label width="140">
        <template #default="scope">
          <el-input
            v-model="scope.row.decimal_amount"
            clearable
            placeholder="金额"
            @change="change"
          >
            <template #prefix>￥</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label>
        <template #default="scope">
          <el-date-picker
            v-model="scope.row.date"
            :clearable="false"
            placeholder="付款日期"
            style="width: 130px"
            @change="change"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :disabled-date="(date) => date > new Date()"
          ></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label width="80">
        <template #default="scope">
          <el-popover :width="300" trigger="click">
            <template #reference>
              <el-button type="primary" link size="small">备注</el-button>
            </template>
            <el-input
              v-model="scope.row.remark"
              placeholder="备注信息"
              type="textarea"
              @change="change"
            ></el-input>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label width="80">
        <template #default="scope">
          <el-icon
            style="margin-left: 20px"
            @click="receiptList.splice(scope.$index, 1)"
          >
            <Delete />
          </el-icon>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" link style="margin-top: 20px" @click="addReceipt"
      >+添加收款</el-button
    >
  </div>
</template>

<script>
import date_util from "@/utils/date_util";
export default {
  data() {
    return {
      //收款项
      pay_term_list: [],
      //收款数据列表
      receiptList: [],
    };
  },
  props: ["enums", "amount"],
  emits: ["change"],
  created() {
    this.$http.get("seller/v1/paymentTerm").then((res) => {
      if (res.code == 0) {
        this.pay_term_list = res.data;
      }
    });
  },

  methods: {
    /**
     * 添加收款
     */
    addReceipt() {
      this.receiptList.push({
        date: date_util.formatDate(new Date()),
        remark: "",
        decimal_amount: this.receiptList.length <= 0 ? this.amount : "",
        payment_term_id: "",
        type: 0,
      });
    },

    /**
     *
     */
    change() {
      this.$emit("change", this.receiptList);
    },
  },
};
</script>

<style scoped>
</style>